<!--
 * @Descripttion: 
 * @Author: 朱东帅
 * @Date: 2023-02-15 14:13:25
 * @LastEditors: 朱东帅
 * @LastEditTime: 2023-03-03 14:24:15
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isMobile: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f4f4f4;
}
</style>
