import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/user-login',
    component: () => import('./../views/Login/UserLogin.vue'),
    hidden: true
  },
  {
    path: '/user-login-mobile',
    component: () => import('./../views/Login/UserLoginMobile.vue'),
    hidden: true
  },

  {
    path: '/user-agreement',
    component: () => import('./../views/Agreement/UserAgreementContent.vue'),
    hidden: true
  },

  {
    path: '/user-policy',
    component: () => import('./../views/Agreement/UserPrivacyPolicyContent.vue'),
    hidden: true
  },

  {
    path: '/',
    redirect: '/user-login-mobile'
  },
  {
    path: '/404',
    component: () => import('./../views/404/404.vue'),
    hidden: true
  },
  { path: '*', redirect: '/user-login-mobile', hidden: true }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
